exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-funcionalidades-js": () => import("./../../../src/pages/funcionalidades.js" /* webpackChunkName: "component---src-pages-funcionalidades-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-precos-js": () => import("./../../../src/pages/precos.js" /* webpackChunkName: "component---src-pages-precos-js" */),
  "component---src-pages-termos-de-servico-js": () => import("./../../../src/pages/termos-de-servico.js" /* webpackChunkName: "component---src-pages-termos-de-servico-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-markdown-blog-post-js": () => import("./../../../src/templates/markdown-blog-post.js" /* webpackChunkName: "component---src-templates-markdown-blog-post-js" */)
}

